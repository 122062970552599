<template>
	<v-row v-if="isThemeFetched">
		<v-col cols="4">
			<figure
				class="login__background secondary-contrast-background"
				:style="{ backgroundImage: `url(${backgroundImage})` }"
			></figure>
		</v-col>

		<v-col cols="8">
			<article class="login__form">
				<section class="login__form--wrapper">
					<figure class="text-center">
						<router-link to="/">
							<img class="login__form--logo" :src="logoUrl" />
						</router-link>
					</figure>
					<h1 class="login__form--title text-center primary-text">
						Sign in to your account
					</h1>
					<v-form ref="form" v-model="valid">
						<v-text-field
							label="E-mail Address"
							v-model="email"
							:rules="[rules.email, rules.required]"
						></v-text-field>
						<v-text-field
							label="Password"
							v-model="password"
							:append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
							:type="showPass ? 'text' : 'password'"
							name="input-10-1"
							hint="At least 5 characters"
							:rules="[rules.required]"
							@click:append="showPass = !showPass"
						></v-text-field>
						<FormMessage v-if="message"> {{ message }}</FormMessage>
						<v-input class="login__form--reset primary-text"
							>Forgot your password?</v-input
						>
						<v-btn
							class="mt-8 secondary-contrast-background contrast-text"
							block
							elevation="2"
							large
							:loading="isLoading"
							@click="login"
							>login</v-btn
						>

						<!-- <p class="text-center mt-5">
							<small>No account yet?</small>
						</p>
						<p class="text-center">
							<router-link to="/registration"
								>Register New Account</router-link
							>
						</p> -->
					</v-form>
				</section>
			</article>
		</v-col>
	</v-row>
</template>

<script>
import { mapGetters } from "vuex";
import FormMessage from "../../components/FormMessage";

export default {
	name: "LoginView",
	components: { FormMessage },
	computed: {
		...mapGetters([
			"isAdmin",
			"isLoading",
			"isThemeFetched",
			"organization"
		]),
		logoUrl() {
			if (this.$store.state.organization.logo?.file) {
				return this.$store.state.organization.logo?.file?.public_url;
			} else {
				return require("@/assets/whsa_logo.svg");
			}
		},
		organizationId() {
			if (this.$route.query.organizationId !== undefined) {
				return this.$route.query.organizationId;
			} else if (this.organization?.id !== undefined) {
				return this.organization.id;
			} else if (localStorage.getItem("organizationId")) {
				return localStorage.getItem("organizationId");
			} else {
				return 1;
			}
		},
		backgroundImage() {
			if (
				this.$store.state.organization.homePageMainBackgroundImage?.file
			) {
				return this.$store.state.organization
					.homePageMainBackgroundImage?.file?.public_url;
			} else {
				return "https://f9z3k9s3.stackpathcdn.com/wp-content/uploads/2020/05/rule-one-800x800-c-default.jpg";
			}
		}
	},

	data: () => ({
		showPass: false,
		password: null,
		email: null,
		valid: null,
		rules: {
			required: value => !!value || "Required.",
			counter: value => value.length <= 20 || "Max 20 characters",
			email: value => {
				const pattern =
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				return pattern.test(value) || "Invalid e-mail.";
			}
		},
		message: ""
	}),
	methods: {
		async validate() {
			return await this.$refs.form.validate();
		},
		async login() {
			this.message = "";
			try {
				if ((await this.validate()) === false) {
					this.message = "Check fields";
					return;
				}
				await this.$store.dispatch("setIsLoading", true);
				const response = await this.$store.dispatch("login", {
					email: this.email,
					password: this.password
				});
				if (response.status === 200 || response.status === 201) {
					const redirectView = this.isAdmin
						? "AdminDashboard"
						: "StudentDashboard";
					this.$router.push({ name: redirectView });
				}
			} catch (error) {
				this.message = error.response.data.message;
			}
			await this.$store.dispatch("setIsLoading", false);
		}
	},

	async beforeMount() {
		localStorage.setItem("organizationId", this.organizationId);
		await this.$store.dispatch("getOrganizationInfo", this.organizationId);
		await this.$store.dispatch("setThemeColors");

		this.$store.dispatch("setIsThemeFetched", true);
		const isLogged = this.$store.state.user.isLogged;
		if (isLogged) {
			// const redirectView = this.isAdmin ? "AdminDashboard" : "Dashboard";
			this.$router.push({ name: "AdminDashboard" });
		}
	}
};
</script>
